<template>
    <hb-modal v-model="dialog" size="large" title="Filter By" show-help-link>
        <template v-slot:subheader>
            <span class="hb-text-light">Create 2-3 sentences that describes the overview of this form.</span>
        </template>
        <template v-slot:content>
            <hb-form label="Assignment" description="Select one or multiple role(s) AND/OR user(s) to filter from.">
                <hb-combobox 
                    :search-input.sync="searchCombobox.assignees"
                    @blur="clearSearchCombobox('assignees')"
                    @keydown.enter="clearSearchCombobox('assignees')"
                    :items="taskAssignees" 
                    v-model="filterData.assignees" item-text="name" item-value="id"
                    append-icon="" prepend-inner-icon="mdi-magnify" label="Search roles or users" />
            </hb-form>

            <hb-form label="Task Association" description="Select the object to filter by." required>
                <hb-radio-group row hide-details v-model="filterData.association">
                    <hb-radio label="Nothing" :value="0"></hb-radio>
                    <hb-radio label="Property" :value="1"></hb-radio>
                    <hb-radio label="Space" :value="2"></hb-radio>
                    <hb-radio label="Tenant" :value="3"></hb-radio>
                </hb-radio-group>
    
                <v-col class="px-0 pt-4" v-if="filterData.association">
                    <p class="hb-text-light">
                        Select property or properties to filter from.
                    </p>
                    <hb-combobox class="mt-4" 
                        :search-input.sync="searchCombobox.properties"
                        @blur="clearSearchCombobox('properties')"
                        @keydown.enter="clearSearchCombobox('properties')"
                        v-model="filterData.selectedProperties" v-validate="'required'" :items="propertyItems" id="primary_properties"
                        name="primary_properties" label="Select Properties"
                        data-vv-name="primary_properties" data-vv-as="Properties"
                        :error="errors.has('primary_properties')" item-value="id" item-text="name" return-object>
                    </hb-combobox>
    
                    <p class="hb-text-light pt-1">
                        <span v-if="filterData.association === 2">Select space(s) to filter from.</span>
                        <span v-if="filterData.association === 3">Select tenant(s)  to filter from.</span>
                    </p>
    
                    <hb-combobox
                        v-if="filterData.association === 2 || filterData.association === 3"
                        v-model="filterData.associations"
                        :items="associationList"
                        @update:search-input="searchSpaceorTenatEvent"
                        item-text="name"
                        :loading="isFetching"
                        item-value="id"
                        :label="'Select ' + (filterData.association === 2 ? 'space(s)' : 'tenant(s)' )"
                        class="mt-4"
                        :search-input.sync="searchCombobox.association"
                        @blur="clearSearchCombobox('association')"
                        @keydown.enter="clearSearchCombobox('association')"
                    >
                        <template v-slot:item="{item, attrs}">
                            <v-list-item-action> 
                                <v-icon v-if="attrs.inputValue">check_box</v-icon> 
                                <v-icon v-else :color="item.disabled ? '#DFE3E8' : '#9b9b9b'">check_box_outline_blank</v-icon>
                            </v-list-item-action>
                            <v-list-item-content> 
                                <v-list-item-title v-if="filterData.association === 2"> 
                                    <hb-icon small>mdi-facility-custom-3</hb-icon> 
                                    <span class="top--2px">{{ item.name }}</span>
                                    <hb-icon small>mdi-facility-custom-2</hb-icon> 
                                    <span class="top--2px">{{ item.property_number }}</span>
                                </v-list-item-title> 
                                <v-list-item-title v-else>
                                    <span class="top--2px pr-0">{{ item.name }}</span>, 
                                    <span>
                                        <hb-icon small>mdi-facility-custom-3</hb-icon> 
                                        <span class="top--2px">{{ item.unit_number }}</span>
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content> 
                        </template>
                    </hb-combobox>
                </v-col>
            </hb-form>

            <hb-form label="Created by">
                <hb-combobox 
                    v-model="filterData.createdByList" 
                    :search-input.sync="searchCombobox.created"
                    @blur="clearSearchCombobox('created')"
                    @keydown.enter="clearSearchCombobox('created')"
                    item-text="name" item-value="id" 
                    label="Filter by person who created the task"
                    :items="createdByList"></hb-combobox>
            </hb-form>

            <hb-form label="Status">
                <v-checkbox label="Incomplete" value="pending" hide-details class="pt-0 mt-0"
                    v-model="filterData.statuses" />
                <v-checkbox label="Overdue" value="overdue" hide-details class="pt-0 mt-0"
                    v-model="filterData.statuses" />
                <v-checkbox label="Completed" value="completed" hide-details class="pt-0 mt-0"
                    v-model="filterData.statuses" />
            </hb-form>

            <div class="subtypes-list" v-if="taskSubTypes && Object.keys(taskSubTypes).length">
                <p class="mb-1">Subtypes</p>
                <v-expansion-panels v-model="accordionTypes" multiple>
                    <hb-expansion-panel v-for="item in Object.keys(taskSubTypes)">
                        <template v-slot:title>
                            {{ item | capitalize }}
                        </template>
                        <template v-slot:content>
                            <v-col>
                                <v-checkbox v-for="type in taskSubTypes[item]" :label="type.name" :value="type.id" class="pb-1" hide-details v-model="filterData.types" />
                            </v-col>
                        </template>
                    </hb-expansion-panel>
                </v-expansion-panels>
            </div>
        </template>

        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" :loading="isLoading($options.name)"
                @click="applyFilters">Apply
            </hb-btn>
        </template>
    </hb-modal>
</template>

<script>

import { mapGetters } from 'vuex';
import api from '../../../assets/api.js'

export default {
    name: 'TasksFilterModal',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        taskAssignees: {
            type: Array,
            default: []
        },
        taskSubTypes: {
            type: Object,
            default: null
        },
        fetchTasks: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            filterData: {
                assignees: [],
                association: '',
                selectedProperties: [],
                associations: [],
                createdByList: [],
                statuses: [],
                types: []
            },
            associationList: [],
            isFetching: false,
            accordionTypes: [],
            searchCombobox: {}
        }
    },
    mounted() {
        if (this.filtered.length === 1) {
            this.filterData.selectedProperties = this.filtered;
        }
    },
    computed: {
        ...mapGetters({
            filtered: 'propertiesStore/filtered'
        }),
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        propertyItems() {
            return this.filtered.map(p => {
                return {
                    id: p.id,
                    name: (p.number ? p.number + ' - ' : '') + (p.city ? p.city + ' - ' : '') + p.name,
                    number: p.number,
                    city: p.city,
                    gds_id: p.gds_id,
                    disabled: false,
                }
            })
        },
        createdByList() {
            return this.taskAssignees.length ? this.taskAssignees.filter(item => item.is_admin) : []
        }
    },
    watch: {
        'filterData.association': {
            handler: function(params) {
                this.filterData = {
                    ...this.filterData,
                    selectedProperties: [],
                    associations: []
                };
                this.associationList = [];
            }
        }
    },
    methods: {
        applyFilters() {
            let associate;
            switch (this.filterData.association) {
                case 1:
                    associate = 'property'
                    break;
                case 2:
                    associate = 'space'
                    break;
                case 3:
                    associate = 'contact'
                    break;
                default:
                    associate = ''
                    break;
            }
            let filterPayload = {
                ...(this.filterData.assignees?.length && { assigned_to: this.filterData.assignees.filter(item => item && item.id).map(item => { 
                        return {
                            type: item.is_role ? 'role' : 'user',
                            id: item.id
                        }
                    })
                }),
                ...((this.filterData.associations?.length || this.filterData.selectedProperties?.length) && { associated_with: this.filterData[associate === 'property' ? 'selectedProperties' : 'associations'].filter(item => item && item.id).map(item => { 
                        return {
                            type: associate,
                            id: item.id
                        }
                    })
                }),
                ...(this.filterData.createdByList?.length && { created_by_ids: this.filterData.createdByList.map(item => { 
                        return {
                            id: item.id
                        }
                    })
                }),
                ...(this.filterData.statuses?.length && { statuses: this.filterData.statuses }),
                ...(this.filterData.types?.length && { task_type_ids: this.filterData.types.map(item => { 
                        return {
                            id: item
                        }
                    })
                }),
            }
            this.fetchTasks(true, filterPayload && Object.keys(filterPayload).length ? filterPayload : null);
        },
        searchSpaceorTenatEvent: _.debounce(async function(params, force) {
            if(!params || !params.trim()) return;
            if (this.filterData.association !== 3 && !this.filterData.selectedProperties.length) {
                this.showMessageNotification({type: 'error', description: "Please select property first"});
                return;
            }
            this.isFetching = true;
            try {
                let payload = {
                    "search": params.trim().toLowerCase(),
                    "property_ids": this.filterData.association === 3 && !this.filterData.selectedProperties.length ? this.filtered.map(item => item.id).filter(item => item) : this.filterData.selectedProperties.map(item => item.id).filter(item => item)
                }
                let response = await api.post(this, api.PROPERTIES + `/search/${this.filterData.association === 2 ? 'spaces' : 'tenants'}`, payload);
                this.associationList = response.data;
            } catch (error) {
                this.showMessageNotification({type: 'error', description: error });
            } finally {
                this.isFetching = false;
            }
        }, 1000),
        clearSearchCombobox(params) {
            if(this.searchCombobox[params]) this.searchCombobox[params] = '';
        },
    }
}
</script>


<style scoped lang="scss">
.subtypes-list {
    background-color: #F9FAFB;
    padding: 16px;

    p {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0px;
    }
}

.top--2px {
    position: relative;
    top: -2px;
    padding: 0 4px;
    padding-right: 8px;
} 
</style>